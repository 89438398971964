/* Import the custom font */
@font-face {
    font-family: 'Inter';
    src: url('./font/Inter-VariableFont_opsz,wght.ttf') format('truetype');
    font-weight: 100 900;
    font-display: swap;
}

/* Reset and base styles */
html,
body {
    height: 100%;
    margin: 0;
    box-sizing: border-box;
    font-family:
        'Inter',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* Code font style */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
